import { AuthenticationResult, IPublicClientApplication } from '@azure/msal-browser';
import { sleep } from './sleep';

export const msalSettings = {
    scopes: ['openid'],
    claims: 'onprem_sid,groups',
};

interface TokenClaims {
    exp: string;
}

const state = {
    loggingIn: false,
};

const processLogin = async (msalInstance: IPublicClientApplication): Promise<AuthenticationResult> => {
    const { auth } = msalInstance.getConfiguration();
    let login;
    try {
        login = await msalInstance.acquireTokenSilent({
            ...auth,
            ...msalSettings,
        });
    } catch (e: any) {
        console.error(e);
        if (e.errorCode === 'invalid_claims') {
            // refresh token expired so do a new login
            await sleep(2000);
            try {
                await msalInstance.loginRedirect({
                    scopes: ['user.read']
                });
            } catch(e) {
                console.error(e);
                throw e;
            }
        }
        throw e;
    }

    const { exp } = login.idTokenClaims as TokenClaims;
    const expIsoString = new Date((exp as unknown as number) * 1000).toISOString();
    const newIsoString = new Date().toISOString();

    if (expIsoString < newIsoString) {
        try {
            console.log('Token expired. Fetching new idToken');
            const login = await msalInstance.acquireTokenSilent({
                ...auth,
                ...msalSettings,
                forceRefresh: true,
            });
            state.loggingIn = false;
            return login;
        } catch (e) {
            // refresh token expired so do a new login
            await sleep(2000);
            try {
                await msalInstance.loginRedirect({
                    scopes: ['user.read']
                });
            } catch(e) {
                console.error(e);
                throw e;
            }
        }
    }

    state.loggingIn = false;
    return login;
};

export const getLogin = async (msalInstance: IPublicClientApplication): Promise<AuthenticationResult> => {
    if (state.loggingIn === false) {
        state.loggingIn = true;
        return await processLogin(msalInstance);
    } else {
        await sleep(2000);
        while (state.loggingIn === true) {
            await sleep(2000);
        }
        state.loggingIn = true;
        return await processLogin(msalInstance);
    }
};