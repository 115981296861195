import { UserExport } from '../Types';

const getUsername = (userId: string, users: UserExport[]) => {
  const user = users.find((user: any) => user.userId === userId);
  if (user === undefined) {
      return userId;
  }
  return user.name;
};

export default getUsername;