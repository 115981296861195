import React, { useCallback, useEffect, useState } from 'react';
import SlackIcon from '../../assets/Icons/SlackIntegrationIcons/Slack.png';
import ArrowsIcon from '../../assets/Icons/SlackIntegrationIcons/Arrows.png';
import AbsenceToolIcon from '../../assets/Icons/SlackIntegrationIcons/AbsenceTool.png';
import { useMsal } from '@azure/msal-react';
import { getLogin } from '../../helpers/authHelper';
import axios from 'axios';
import { Link } from 'react-router-dom';

const SlackIntegration = () => {
  const [slackIntegration, setSlackIntegration] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const msal = useMsal();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const login = await getLogin(msal.instance);

        await axios.get(`${process.env.REACT_APP_BACKEND_BASEURL}/integration/slack/status`, {
          headers: {
            Authorization: `Bearer ${login.idToken}`,
          },
        });
        setSlackIntegration(true);
      } catch(error) {
        setSlackIntegration(false);
        console.error(error);
      }
      setFetching(false);
    };
    fetchData();
    
  },[msal.instance, refresh]);

  const revokeSlackToken = useCallback(async () => {
    try {
      const login = await getLogin(msal.instance);

      await axios.get(`${process.env.REACT_APP_BACKEND_BASEURL}/integration/slack/revoke`, {
        headers:  {
          Authorization: `Bearer ${login.idToken}`,
        },
      });
    } catch(error) {
      alert('Failed to revoke token');
      console.error(error);
    }
    setRefresh((p) => !p);
  },[msal.instance]);

  return <div className='container' style={{alignItems: 'center'}}>
    <div className='holiday-container'>
      
        <div className='slack-integration-title'>
          <img src={AbsenceToolIcon} alt='absence-tool'/>
          <img src={ArrowsIcon} alt='arrows-icon' style={{padding: '0 1em'}}/>
          <img src={SlackIcon} alt='slack-icon'/>
        </div>
        
        <div className='slack-integration-container'>
          {fetching ? <p className='loading-text'>Fetching...</p> :
          <React.Fragment>
            <b>Integrate Absence Tracking Tool to your Slack account</b>
            <ul>
              <li>Your Slack status will be automatically updated <b>on the day of your absence</b></li>
              <li>Others can see if you're on Vacation or Sick Leave. Other types of absences are marked as Vacationing</li>
            </ul>
            {slackIntegration ? <React.Fragment>
              <div className='slack-integration-greyed'>
              You are already integrated to Slack. Do you want to remove integration?
              </div>
              <button className='request-button' onClick={() => {
                revokeSlackToken();
              }}>Remove</button>
            </React.Fragment> :
            <Link to={`https://slack.com/oauth/v2/authorize?scope=&user_scope=users.profile%3Awrite&redirect_uri=${encodeURIComponent(process.env.REACT_APP_SLACK_REDIRECT_URI as string)}&client_id=${encodeURIComponent(process.env.REACT_APP_SLACK_APPLICATION_ID as string)}`}>
              <button className='request-button'>Integrate</button>
            </Link>}
          </React.Fragment>}
        </div>
      </div>
  </div>;
};

export default SlackIntegration;