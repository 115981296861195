import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import './Slack.css';

const SlackSuccess = () => {
  return <div className='container' style={{alignItems: 'center'}}>
    <div className='holiday-container'>
      <div className='slack-container'>
        <div className='slack-success-container'>
          <CheckCircleOutlineIcon fontSize='large' sx={{
              color: '#5ca860',
              display: 'flex',
              height: '5rem',
              width: '5rem',
            }} />
        </div>
        <h3 className='slack-success-title'>Success!</h3>
        <div className='slack-success-container' style={{padding: '20px'}}>
          Absence Tracking Tool may now set your Slack presence.
        </div>
      </div>
    </div>
  </div>;
};

export default SlackSuccess;