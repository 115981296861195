import React, { useState } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import AbsenceFormPopup from '../AbsenceForm/AbsenceFormPopUp';
import Logo from '../../assets/Icons/NavBarIcons/Logo.svg';

import './NavBar.css';
import SubMenu from './SubMenu';

interface NavBarProps {
  groups?: string[];
}

const NavBar = (props: NavBarProps) => {
  const { groups } = props;
  const [absenceFormOpen, setAbsenceFormOpen] = useState(false);
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();

  const handleAbsenceFormOpen = () => {
    setAbsenceFormOpen(true);
  };

  const handleAbsenceFormClose = () => {
    setAbsenceFormOpen(false);
  };

  const handleSignIn = async () => {
    await instance.loginRedirect({
      scopes: ['user.read'],
    });
  };

  return (
    <nav id='navbar' className='navbar'>
      <div className='logo-container'>
        <img src={Logo} alt='Logo' className='logo' />
        <span className='logo-text'>Absences</span>
      </div>
      <div className='nav-items'>
        {isAuthenticated ? (
          <>
            <RouterLink
              to='/'
              className={`nav-button ${location.pathname === '/' ? 'active' : ''}`}
              style={{ width: '138px' }}
            >
              Dashboard
            </RouterLink>
            <RouterLink
              to='/Calendar'
              className={`nav-button ${location.pathname === '/Calendar' ? 'active' : ''
                }`}
              style={{ width: '203px' }}
            >
              Absence Calendar
            </RouterLink>
            {groups !== undefined && groups.includes(process.env.REACT_APP_LEAD_GROUP as string) ?
              <RouterLink
                to='/LeadView'
                className={`nav-button ${location.pathname === '/LeadView' ? 'active' : ''
                  }`}
                style={{ width: '134px' }}
              >
                My Team
              </RouterLink> : null}
            {groups !== undefined &&
              (groups.includes(process.env.REACT_APP_MANAGEMENT_GROUP as string) ||
              groups.includes(process.env.REACT_APP_HR_GROUP as string)) ?
              <RouterLink
                to='/ManagementView'
                className={`nav-button ${location.pathname === '/ManagementView' ? 'active' : ''
                  }`}
                style={{ width: '151px' }}
              >
                Management
              </RouterLink> : null}
            <button
              className='nav-button request-button'
              style={{ width: '243px'}}
              onClick={handleAbsenceFormOpen}
            >
              Request Absence
            </button>
            <div className='divider' />
            <SubMenu msalInstance={instance}/>
          </>
        ) : (
           <div className='sign-in-button-container'>
              <button className='nav-button sign-in' onClick={async () => await handleSignIn()}>
              Sign In
            </button>
          </div>
        )}
      </div>
      <AbsenceFormPopup
        open={absenceFormOpen}
        closePopUp={() => handleAbsenceFormClose()}
      />
    </nav>
  );
};

export default NavBar;
