import React from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import './Slack.css';

const SlackFailure = () => {
  return <div className='container' style={{alignItems: 'center'}}>
  <div className='holiday-container'>
    <div className='slack-container'>
      <div className='slack-success-container'>
        <HighlightOffIcon fontSize='large' sx={{
            color: '#000',
            display: 'flex',
            height: '5rem',
            width: '5rem',
          }} />
      </div>
      <h3 className='slack-success-title'>Error</h3>
      <div className='slack-success-container' style={{padding: '20px', textAlign: 'center'}}>
        An error has occured during your request. Please try again or contact IT support.
      </div>
    </div>
  </div>
</div>;
};

export default SlackFailure;