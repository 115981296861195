import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './AbsenceForm.css';
import { useMsal } from '@azure/msal-react';
import { FormValues, AbsenceFormProps, AbsenceReasons as absenceReasons, AbsenceReason, User, Holiday, BankHoliday } from '../../Types';
import { fetchBankHolidays, getCountry, getNumberOfDays, handleCreateAbsence } from '../../helpers/absenceRequests';
import axios from 'axios';
import { getLogin } from '../../helpers/authHelper';
import { getHolidayBalance } from '../../helpers/holidays';

const getHolidayClassName = (date: Date, bankHolidays: BankHoliday[] | undefined) => {
  const getBankHolidaysFiltered = (bankHolidays: BankHoliday[] | undefined) => {
    if (bankHolidays === undefined) {
      return [];
    }
    return bankHolidays.filter((bankHoliday) => {
      const bankHolidayUtc = new Date(bankHoliday.date);
      bankHolidayUtc.setUTCFullYear(bankHolidayUtc.getFullYear(), bankHolidayUtc.getMonth(), bankHolidayUtc.getDate());
      bankHolidayUtc.setUTCHours(0, 0, 0, 0);
      return bankHolidayUtc.toISOString() === utcDate.toISOString();
    });
  };
  const utcDate = new Date(date);
  utcDate.setUTCFullYear(utcDate.getFullYear(), utcDate.getMonth(), utcDate.getDate());
  utcDate.setUTCHours(0, 0, 0, 0);

  const isWeekend = utcDate.getUTCDay() === 0 || utcDate.getUTCDay() === 6;

  const bankHolidaysFiltered = getBankHolidaysFiltered(bankHolidays);
  const isBankHoliday = bankHolidaysFiltered.length > 0;
  const result = (isWeekend || isBankHoliday) ? 'calendar-holiday' : null;
  
  return result;
};

const AbsenceForm: React.FC<AbsenceFormProps> = ({
  closePopUp,
}) => {
  const msal = useMsal();
  const [formValues, setFormValues] = useState<FormValues>({
    startDate: null,
    endDate: null,
    reason: '' as AbsenceReason,
    information: '',
    userId: msal.instance.getActiveAccount()?.idTokenClaims?.onprem_sid as string,
  });
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [employees, setEmployees] = useState<User[] | undefined>(undefined);
  const [holidays, setHolidays] = useState<Holiday[]>([]);
  const [bankHolidays, setBankHolidays] = useState<BankHoliday[] | undefined>(undefined);
  const [numberOfDays, setNumberOfDays] = useState<number | undefined>();

  useEffect(() => {
    const fetchEmployees = async() => {
      try {
        const login = await getLogin(msal.instance);
        const { groups } = login.idTokenClaims as any;

        const { data } = await axios.get<User[]>(`${process.env.REACT_APP_BACKEND_BASEURL}/users`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${login.idToken}`,
          }
        });

        if (groups.includes(process.env.REACT_APP_MANAGEMENT_GROUP) ||
          groups.includes(process.env.REACT_APP_HR_GROUP)
        ) {
          setEmployees(data.sort((a, b) => a.name.localeCompare(b.name)));
        } else if (groups.includes(process.env.REACT_APP_LEAD_GROUP)) {
          const employees = data
            .filter((employee) =>
              employee.leadId === msal.instance.getActiveAccount()?.idTokenClaims?.onprem_sid ||
              (employee.leadId !== msal.instance.getActiveAccount()?.idTokenClaims?.onprem_sid &&
              employee.userId === msal.instance.getActiveAccount()?.idTokenClaims?.onprem_sid)
            ).sort((a, b) => a.name.localeCompare(b.name));
          if (employees !== undefined) {
            setEmployees(employees);
          }
        } else {
          const employee = data.find((employee) => employee.userId === msal.instance.getActiveAccount()?.idTokenClaims?.onprem_sid);
          if (employee !== undefined) {
            setEmployees([employee]);
          }
        }
      } catch(e) {
        console.error(e);
      }
    };
    fetchEmployees();
  },[msal.instance]);

  useEffect(() => {
    const fetchHolidays = async() => {
      try {
        const login = await getLogin(msal.instance);

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASEURL}/holidays`, {
          headers: {
            Authorization: `Bearer ${login.idToken}`,
          },
        });

        setHolidays(response.data);
      } catch (error) {
        console.error('Error fetching holidays:', error);
      }
    };
    fetchHolidays();
  },[msal.instance]);

  useEffect(() => {
    const fetch = async() => {
      if (employees) {
        const countries = new Set<string>();
        const country = getCountry(formValues.userId === undefined ? '' : formValues.userId, employees);
        countries.add(country);
        const bankHolidaysResponse = await fetchBankHolidays(countries, new Date().getUTCFullYear(), msal.instance);
        const bankHolidaysResponseNextYear = await fetchBankHolidays(countries, new Date().getUTCFullYear()+1, msal.instance);
        if (bankHolidaysResponseNextYear !== undefined) {
          bankHolidaysResponse?.push(...bankHolidaysResponseNextYear);
        }
        if (bankHolidaysResponse !== undefined) {
          setBankHolidays(bankHolidaysResponse);
        }
      }
    };
    fetch();
  },[formValues.userId, employees, msal.instance]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitDisabled(true);
    if (formValues.startDate && formValues.endDate) {
      const startDateIsoString = formValues.startDate.toISOString();
      const endDateIsoString = formValues.endDate.toISOString();

      if (startDateIsoString > endDateIsoString) {
        alert('Start Date is after End Date');
        setSubmitDisabled(false);
        return;
      }

      try {
        formValues.startDate.setUTCFullYear(formValues.startDate.getFullYear(), formValues.startDate.getMonth(), formValues.startDate.getDate());
        formValues.startDate.setUTCHours(0, 0, 0, 0);
        formValues.endDate.setUTCFullYear(formValues.endDate.getFullYear(), formValues.endDate.getMonth(), formValues.endDate.getDate());
        formValues.endDate.setUTCHours(0, 0, 0, 0);
        await getNumberOfDays(
          formValues.startDate,
          formValues.endDate,
          msal.instance,
          formValues.userId === undefined ? undefined : getCountry(formValues.userId, employees === undefined ? [] : employees)
        );
      } catch(e) {
        alert('No working days in selected time period');
        setSubmitDisabled(false);
        return;
      }

      const createdAbsence = await handleCreateAbsence(formValues, msal.instance);
      if (createdAbsence) {
        closePopUp();
      }
    } else {
      alert('Please select both start and end date.');
    }
    setSubmitDisabled(false);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  useEffect(() => {
    const checkNumberOfDays = async() => {
      if (employees && employees.length > 0 && formValues.userId !== undefined && formValues.startDate !== null && formValues.endDate !== null) {
        if (formValues.reason === 'Vacation') {
          const { startDate, endDate } = formValues;
          startDate.setUTCFullYear(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
          startDate.setUTCHours(0, 0, 0, 0);
          endDate.setUTCFullYear(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
          endDate.setUTCHours(0, 0, 0, 0);
          const numberOfDays = await getNumberOfDays(startDate, endDate, msal.instance, getCountry(formValues.userId, employees));
          
          setNumberOfDays(numberOfDays);
        } else {
          setNumberOfDays(0);
        }
      } else {
        setNumberOfDays(undefined);
      }
    };
    checkNumberOfDays();
  }, [formValues, formValues.startDate, formValues.endDate, formValues.userId, employees, msal.instance]);

  return (
    <form
      className={'absence-form'}
      onSubmit={handleSubmit}
    >
      {employees && employees.length > 1 ? <div>
        <label htmlFor='userId'>For:</label>
        <br />
        <select
          id='employee'
          name='userId'
          value={formValues.userId}
          onChange={handleChange}
        >
          {employees.map((user, index) => {
            return <option key={index} value={user.userId}>
              {user.name}
            </option>;
          })}

        </select>
      </div> : <br />}
      {holidays.length > 0 ?
      <div>
        <label htmlFor='userId'>
          {formValues.userId === msal.instance.getActiveAccount()?.idTokenClaims?.onprem_sid ? 'You have' : 'Has'} {getHolidayBalance(formValues.userId as string, holidays)} Vacation days left
        </label>
        <br />
        <br />
      </div> : 
      <div>
        <label htmlFor='userId'>Fetching...</label>
        <br />
        <br />
      </div>}
      <div>
        <label htmlFor='reason'>Reason:</label>
        <br />
        <select
          id='reason'
          name='reason'
          value={formValues.reason}
          onChange={handleChange}
        >
          <option value=''>Select Absence</option>
          {absenceReasons.map((reason, index) => {
            return <option key={index} value={reason}>
              {reason === 'Day Off' ? 'Day Off using Flexi Time' : reason}
            </option>;
          })}
        </select>
        {(formValues.reason as AbsenceReason | '') !== '' ?
        <React.Fragment>
          <br />
          {formValues.reason === 'Vacation' ? <label style={{color: '#BF0629'}}>Reduces Vacation Days</label> : <label>Does not reduce vacation days</label>}
          <br />
          <br />
        </React.Fragment> :
        <React.Fragment>
          <br />
          <br />
          <br />
        </React.Fragment>
        }
      </div>
      <div>
        <label htmlFor='startDate'>Start Date:</label>
        <br />
        <DatePicker
          id='startDate'
          selected={formValues.startDate}
          onChange={(date) =>
            setFormValues((prevValues) => ({ ...prevValues, startDate: date }))
          }
          selectsStart
          startDate={formValues.startDate}
          endDate={formValues.endDate}
          maxDate={formValues.endDate}
          dateFormat='dd/MM/yyyy'
          calendarStartDay={1}
          dayClassName={(date) => getHolidayClassName(date, bankHolidays)}
        />
      </div>
      <div>
        <label htmlFor='endDate'>End Date:</label>
        <br />
        <DatePicker
          id='endDate'
          selected={formValues.endDate}
          onChange={(date) =>
            setFormValues((prevValues) => ({ ...prevValues, endDate: date }))
          }
          selectsEnd
          startDate={formValues.startDate}
          endDate={formValues.endDate}
          minDate={formValues.startDate}
          dateFormat='dd/MM/yyyy'
          calendarStartDay={1}
          dayClassName={(date) => getHolidayClassName(date, bankHolidays)}
        />
      </div>
      {formValues.userId !== undefined && formValues.startDate !== null && formValues.endDate !== null ?
        <React.Fragment>
          <label htmlFor='endDate'>Number of Vacation days: {numberOfDays}</label>
          <br />
          <br />
        </React.Fragment> :
        <React.Fragment>
          <br />
          <br />
        </React.Fragment>
      }
      <div>
        <label htmlFor='information'>Note:</label>
        <br />
        <input
          type='text'
          id='information'
          name='information'
          className='information'
          value={formValues.information}
          onChange={handleChange}
          required={formValues.reason !== 'Sick Leave'}
        />
      </div>
      <div className='buttons'>
        <button
          type='submit'
          disabled={!absenceReasons.includes(formValues.reason) || submitDisabled}
          className='absence-submit-button'
        >
          Submit
        </button>
        <button
          onClick={closePopUp}
          className='absence-cancel-button'
        >
          Cancel
        </button>
      </div>
    </form>
  );
};
export default AbsenceForm;
