import { IPublicClientApplication } from '@azure/msal-browser';
import { Absence, EmployeeInterface, Holiday } from '../Types';
import { getNumberOfDays } from './absenceRequests';

// Javascript month index starts at 0
const vacationDayTable = [
  2, // January
  2, // February
  2, // March
  2, // April
  2, // May
  2, // June
  2, // July
  2, // August
  2, // September
  2, // October
  2, // November
  3  // December
];

const getPendingHolidayAmountToEndOfYear = () => {
  const now = new Date();
  const daysPendingTable = vacationDayTable.slice(now.getMonth(), vacationDayTable.length);
  const daysPending = daysPendingTable.reduce((acc, curr) => acc + curr, 0);
  return daysPending;
};

const getHolidayBalance = (employeeUserId: string, holidays: Holiday[]): number => {
  let employeeHolidaysCount = 0;
  try {
    for (const holiday of holidays) {
      if (holiday.employeeUserId === employeeUserId) {
        if (holiday.transaction === 'Added') {
            employeeHolidaysCount += holiday.numberOfHolidays;
        } else if (holiday.transaction === 'Reduced') {
            employeeHolidaysCount -= holiday.numberOfHolidays;
        }
      }
    }
    employeeHolidaysCount += getPendingHolidayAmountToEndOfYear();
  } catch(e: any) {
    console.log(e.message);
  }
  return employeeHolidaysCount;
};

const getFutureAbsencesPlannedAmount = async(employee: EmployeeInterface, absences: Absence[], msalInstance: IPublicClientApplication): Promise<number> => {
  const today = new Date();
  const firstOfJanuaryIsoString = new Date(`${new Date().getFullYear()+1}-01-01`).toISOString();
  const lastOfDecemberIsoString = new Date(`${new Date().getFullYear()}-12-31`).toISOString();
  today.setUTCDate(today.getDate());
  today.setUTCHours(0, 0, 0, 0);
  const futureAbsences = absences.filter((absence) => absence.status === 'Approved' &&
    absence.deleted === false &&
    absence.startDate > today.toISOString() &&
    absence.startDate < firstOfJanuaryIsoString
  );
  const futureAbsenceDays = futureAbsences.reduce(async(acc, curr) => {
    try {
      let intermediate = await acc;
      if (curr.endDate >= firstOfJanuaryIsoString) {
        intermediate += await getNumberOfDays(curr.startDate, lastOfDecemberIsoString, msalInstance, employee.location);
        return intermediate;
      } else {
        intermediate += await getNumberOfDays(curr.startDate, curr.endDate, msalInstance, employee.location);
        return intermediate;
      }
    }
    catch (e: any) {
      return acc;
    }
  }, Promise.resolve(0));
  if (futureAbsenceDays === undefined) {
    return 0;
  }
  return futureAbsenceDays;
};

const getHolidayBalanceAsOfToday = async(employee: EmployeeInterface, absences: Absence[], holidays: Holiday[], msalInstance: IPublicClientApplication): Promise<number> => {
  
  return getHolidayBalance(employee.userId, holidays) - getPendingHolidayAmountToEndOfYear() + await getFutureAbsencesPlannedAmount(employee, absences, msalInstance);
};

/*According to UX layout, if balance is over 25 it will be shown red.*/
const getHolidayBalanceColor = (balance: number) => {
  return balance > 25 ? '#BF0629' : '#000';
};

export {
  getHolidayBalance,
  getHolidayBalanceColor,
  getHolidayBalanceAsOfToday,
  getFutureAbsencesPlannedAmount
};