import React from 'react';
import './AbsenceForm.css';
import AbsenceForm from './AbsenceForm';
import { AbsenceFormPopupProps } from '../../Types';
import Union from '../../assets/Icons/CalendarIcons/Absences/Union.svg';

const AbsenceFormPopup: React.FC<AbsenceFormPopupProps> = ({ open, closePopUp, updatePendingRequests }) => {
  if (!open) {
    return null;
  }

  return (
    <div className='absence-popup-overlay' onClick={() => closePopUp()}>
      <div className='absence-popup-content' onClick={(e) => e.stopPropagation()}>
        <button onClick={closePopUp} className='absence-close-button'>
          <img src={Union} alt='Close' className='close-icon' />
        </button>
        <div><h1 className='request-absence-header'>Request time off</h1></div>        
        <AbsenceForm
          closePopUp={closePopUp}
          updatePendingRequests={updatePendingRequests!}
        />
      </div>
    </div>
  );
};

export default AbsenceFormPopup;